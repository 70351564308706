import { IconsType } from 'components/Icon';
import config from 'config/common';

export type EagerImportedSVGModules = {
    default: any;
};

export enum Pages {
    LOGIN = 'login',
    REGISTER = 'register',
    REGISTER_CONFIRMATION = 'register-verification',
    PHONE_VERIFICATION = 'phone-verification',
    EMAIL_VERIFICATION = 'email-verification',
    EMAIL_CONFIRMATION = 'email-confirmation',
    FORGOT_PASSWORD = 'forgot-password',
    NEW_PASSWORD = 'new-password',
    DASHBOARD = 'dashboard',
    MY_WALLET = 'my-wallet',
    MY_WALLET__PRE_CONDITION = 'pre-condition',
    MY_WALLET__DEPOSIT = 'deposit',
    MY_WALLET__DEPOSIT__THANKYOU = 'deposit-thank-you',
    MY_WALLET__WITHDRAW = 'withdraw',
    SUMSUB_UPLOAD = 'kyc-upload',
    KYC_BROKER_17 = 'kyc-broker-17',
    UPLOAD_DOCUMENTS = 'upload-documents',
    PLATFORMS = 'platforms',
    TRADING_ACCOUNTS = 'trading-accounts',
    TRADING_ACCOUNTS__REAL = 'real-accounts',
    TRADING_ACCOUNTS__DEMO = 'demo-accounts',
    TRADING_ACCOUNTS__CREATE = 'create-account',
    TRADING_ACCOUNTS__MONETARY_TRANSACTIONS = 'monetary-transactions',
    TRADING_ANALYSIS = 'trading-analysis',
    TRADING_ANALYSIS__TRADING_FOR_PRO = 'trading-pro',
    TRADING_ANALYSIS__ECONOMIN_CALENDAR = 'economic-calendar',
    TRADING_ANALYSIS__ANALYST_VIEW = 'analyst-view',
    TRADING_ANALYSIS__FEATURED_IDEAS = 'featured-ideas',
    TRADING_ANALYSIS__EDUCATION_ON_DEMAND = 'education-on-demand',
    TRADING_ANALYSIS__WEB_TV = 'web-tv',
    TRADING_ANALYSIS__TRADING_EDUCATION = 'trading-education',
    ACCOUNT_SETTINGS = 'account-settings',
    CLIENT_SUPPORT = 'account-support',
    KYC = 'kyc',
    KYC__0 = 'lets-start',
    KYC__1 = '2',
    KYC__2 = '3',
    KYC__3 = '4',
    KYC__4 = '5',
    KYC__5 = '6',
    KYC__6 = '7',
    KYC__7 = '8',
    KYC__8 = '9',
    KYC__9 = '10',
    KYC__10 = '11',
    KYC__11 = '12',
    KYC__12 = '13',
    KYC__13 = '14',
    BE_PRO = 'be-pro',
    BE_PRO__0 = 'lets-start',
    BE_PRO__1 = '2',
    BE_PRO__2 = '3',
    BE_PRO__3 = '4',
    BE_PRO__4 = '5',
    BE_PRO__5 = '6',
    BE_PRO__6 = '7',
    BE_PRO__7 = 'finish',
    TERMS_AND_CONDITIONS = 'terms-and-conditions',
    PRIVACY_POLICY = 'privacy-policy',
    NOTIFICATIONS = 'notifications',
    NOTIFICATIONS__MESSAGE = 'message',
    DASHBOARD__CLOSE_OPEN_POSITION = 'closeOpenPosition',
    DASHBOARD__CLOSE_PENDING_POSITION = 'closePendingPosition',
    DASHBOARD__CREATE_POSITION = 'createPosition',
    DASHBOARD__CREATE_POSITION__TYPE = 'type',
    FUNDS = 'funds',
    DEVICE__ACTIVITY = 'device-activity',
    MONETARY_TRANSACTIONS = 'monetary-transactions',
    REFER_EARN = 'refer-earn',
    PROP = 'prop',
    PROP__NEW_CHALLENGES = 'new-challenge',
    PROP__NEW_CHALLENGES__NEW = 'new-challenge-new',
    PROP__NEW_CHALLENGES__DEPOSIT = 'deposit',
    PROP__NEW_CHALLENGES__PRE_CONDITION = 'pre-condition',
    PROP__NEW_CHALLENGES__DEPOSIT_SUCCESS = 'deposit-thank-you',
    PROP__MY_CHALLENGES = 'my-challenges',
    PROP__MY_PURCHASES = 'my-purchases',
    PROP__PAYOUT = 'payout',
    PROP__PAYOUT__WITHDRAW = 'withdraw',
    PROP__CHECKOUT = 'checkout',
}

export const getGoBackPath = (
    currectPathname: string,
    asArray = false
): undefined | string | { link: string; labelT: string }[] => {
    switch (currectPathname) {
        case `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__TRADING_FOR_PRO}`:
            if (asArray)
                return [
                    {
                        link: `/${Pages.TRADING_ANALYSIS}`,
                        labelT: 'common:breadcrump.trading_analysis',
                    },
                    {
                        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__TRADING_FOR_PRO}`,
                        labelT: 'common:breadcrump.trading_analysis__trading_for_pro',
                    },
                ];
            return `/${Pages.TRADING_ANALYSIS}`;
        case `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__ECONOMIN_CALENDAR}`:
            if (asArray)
                return [
                    {
                        link: `/${Pages.TRADING_ANALYSIS}`,
                        labelT: 'common:breadcrump.trading_analysis',
                    },
                    {
                        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__ECONOMIN_CALENDAR}`,
                        labelT: 'common:breadcrump.trading_analysis__economic_calendar',
                    },
                ];
            return `/${Pages.TRADING_ANALYSIS}`;
        case `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__ANALYST_VIEW}`:
            if (asArray)
                return [
                    {
                        link: `/${Pages.TRADING_ANALYSIS}`,
                        labelT: 'common:breadcrump.trading_analysis',
                    },
                    {
                        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__ANALYST_VIEW}`,
                        labelT: 'common:breadcrump.trading_analysis__analyst_view',
                    },
                ];
            return `/${Pages.TRADING_ANALYSIS}`;
        case `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__FEATURED_IDEAS}`:
            if (asArray)
                return [
                    {
                        link: `/${Pages.TRADING_ANALYSIS}`,
                        labelT: 'common:breadcrump.trading_analysis',
                    },
                    {
                        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__FEATURED_IDEAS}`,
                        labelT: 'common:breadcrump.trading_analysis__featured_ideas',
                    },
                ];
            return `/${Pages.TRADING_ANALYSIS}`;
        case `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__WEB_TV}`:
            if (asArray)
                return [
                    {
                        link: `/${Pages.TRADING_ANALYSIS}`,
                        labelT: 'common:breadcrump.trading_analysis',
                    },
                    {
                        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__WEB_TV}`,
                        labelT: 'common:breadcrump.trading_analysis__web_tv',
                    },
                ];
            return `/${Pages.TRADING_ANALYSIS}`;

        case `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__EDUCATION_ON_DEMAND}`:
            if (asArray)
                return [
                    {
                        link: `/${Pages.TRADING_ANALYSIS}`,
                        labelT: 'common:breadcrump.trading_analysis',
                    },
                    {
                        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__EDUCATION_ON_DEMAND}`,
                        labelT: 'common:breadcrump.trading_analysis__education_on_demand',
                    },
                ];
            return `/${Pages.TRADING_ANALYSIS}`;
        case `/${Pages.MY_WALLET}/${Pages.MY_WALLET__DEPOSIT__THANKYOU}`:
            if (asArray)
                return [
                    {
                        link: `/${Pages.MY_WALLET}/${Pages.MY_WALLET__DEPOSIT}`,
                        labelT: 'common:breadcrump.my_wallet__deposit',
                    },
                    {
                        link: `/${Pages.MY_WALLET}/${Pages.MY_WALLET__DEPOSIT__THANKYOU}`,
                        labelT: 'common:breadcrump.my_wallet__deposit__thank_you',
                    },
                ];
            return `/${Pages.MY_WALLET}/${Pages.MY_WALLET__DEPOSIT}`;
        case `/${Pages.NOTIFICATIONS}`:
            if (asArray)
                return [
                    {
                        link: `/${Pages.DASHBOARD}`,
                        labelT: 'common:breadcrump.dashboard',
                    },
                    {
                        link: `/${Pages.NOTIFICATIONS}`,
                        labelT: 'common:breadcrump.notifications',
                    },
                ];
            return `/${Pages.DASHBOARD}`;
        case `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`:
            if (asArray)
                return [
                    {
                        link: `/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`,
                        labelT: 'common:breadcrump.prop_my_challenge',
                    },
                    {
                        link: `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`,
                        labelT: 'common:breadcrump.prop_new_challenge',
                    },
                ];
            return `/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`;
    }
    switch (true) {
        case currectPathname.includes(`/${Pages.NOTIFICATIONS}/`) && Boolean(currectPathname.split('/')[2]):
            if (asArray)
                return [
                    {
                        link: `/${Pages.NOTIFICATIONS}`,
                        labelT: 'common:breadcrump.notifications',
                    },
                    {
                        link: `/${Pages.NOTIFICATIONS}/${Pages.NOTIFICATIONS__MESSAGE}`,
                        labelT: 'common:breadcrump.notifications__message',
                    },
                ];
            return `/${Pages.NOTIFICATIONS}`;

        case currectPathname.includes(
            `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}/${Pages.PROP__NEW_CHALLENGES__DEPOSIT}`
        ):
            if (asArray)
                return [
                    {
                        link: `/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`,
                        labelT: 'common:breadcrump.prop_my_challenge',
                    },
                    {
                        link: `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`,
                        labelT: 'common:breadcrump.prop_new_challenge',
                    },
                    {
                        link: `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}/${Pages.PROP__NEW_CHALLENGES__DEPOSIT}`,
                        labelT: 'common:breadcrump.prop_new_challenge_deposit',
                    },
                ];
            return `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`;
        case currectPathname.includes(
            `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}/${Pages.PROP__NEW_CHALLENGES__DEPOSIT_SUCCESS}`
        ):
            if (asArray)
                return [
                    {
                        link: `/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`,
                        labelT: 'common:breadcrump.prop_my_challenge',
                    },
                    {
                        link: `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`,
                        labelT: 'common:breadcrump.prop_new_challenge',
                    },
                    {
                        link: `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}/${Pages.PROP__NEW_CHALLENGES__DEPOSIT_SUCCESS}`,
                        labelT: 'common:breadcrump.prop_new_challenge_deposit_success',
                    },
                ];
            return `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`;
        case currectPathname.includes(`/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`):
            if (asArray)
                return [
                    {
                        link: `/${Pages.DASHBOARD}`,
                        labelT: 'common:breadcrump.dashboard',
                    },
                    {
                        link: `/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`,
                        labelT: 'common:breadcrump.prop_my_challenge',
                    },
                ];
            return `/${Pages.PLATFORMS}`;
        default:
            return undefined;
    }
};

export const isDisclaimerPageOrActive = (currectPathname: string): boolean => {
    if (!config.featuresFlags['disclaimer']) return false;
    const firstPartOfPathname = currectPathname.split('/')[1] || '';
    const disclaimerPages = [Pages.LOGIN, Pages.REGISTER, Pages.FORGOT_PASSWORD, Pages.NEW_PASSWORD];
    return disclaimerPages.some((page) => page === firstPartOfPathname);
};

export enum ThemeOptions {
    LIGHT = 'light',
    DARK = 'dark',
}

export interface SideMenuItem {
    t_name: string;
    icon: IconsType;
    outsideLink?: boolean;
    link?: string;
    possibleLinks?: Array<string>;
    hideLink?: boolean;
    appCode?: string;
    appCodeText?: string;
    hidden?:
        | 'smDown'
        | 'smUp'
        | 'xlDown'
        | 'xlUp'
        | 'xsDown'
        | 'xsUp'
        | 'mdUp'
        | 'mdDown'
        | 'lgUp'
        | 'lgDown'
        | 'forced';
    tradingAnalysisLogic?: boolean;
    onClick?: (e?: any) => void;
    subItemsList?: Array<{ icon: string; t_name: string }>;
    collapsible?: boolean;
    subMenuLinks?: Array<{
        icon: IconsType;
        link: string;
        t_name: string;
        hasChallenges?: boolean;
        hasFilteredChallenges?: boolean;
        hasPayoutChallenges?: boolean;
    }>;
    hasChallenges?: boolean;
    hasFilteredChallenges?: boolean;
    hasChallengeStarted?: boolean;
}

export interface SideMenuList extends Array<SideMenuItem> {}

export enum SocialLoginTypes {
    google = 'google',
    facebook = 'facebook',
}
export enum LoginTypes {
    google = 'google',
    facebook = 'facebook',
    local = 'local',
}

export enum RegisterType {
    REAL = 'real',
    DEMO = 'demo',
}

export interface SingleErrorScheme {
    error: boolean;
    error_label: string;
    touched: boolean;
}

export interface ErrorsScheme {
    [field: string]: SingleErrorScheme;
}

export interface SymbolIcon {
    base?: string;
    currency?: string;
}
export enum ActionType {
    Buy = 0,
    Sell = 1,
}
export enum PendingActionType {
    BuyLimit = 0,
    SellLimit = 1,
    BuyStop = 2,
    SellStop = 3,
}
export enum ProfitCalculationModes {
    Forex = 0,
    CFD = 1,
    Future = 2,
    Sirix = 3,
    MT5Forex = 4,
    MT5CFD = 5,
    MT5Futures = 6,
    MT5CFDIndex = 7,
    MT5CFDLeverage = 8,
}

export const getColorByIndex = (index: number) => {
    const colors = ['#b4dfd1', '#49ba90', '#5da2ba', '#7d9bfb', '#3261fa', '#2a52d3', '#7a3d9f', '#3d4b7a', '#d4e0f2'];
    return colors[index % colors.length];
};

export enum WithdrawalTypes {
    CreditCard = 'CreditCard',
    WireTransfer = 'WireTransfer',
    Crypto = 'Crypto',
    Outsource = 'Outsource',
    TransferBetweenAccounts = 'TransferBetweenAccounts',
}
