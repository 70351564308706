import { usePropStatusHook } from './prop-status.hook';
import { useTranslation } from 'react-i18next';
import { useRetryPlanDuration } from 'hooks/useRetryPlanDuration';
import { PropChallenge, PropPlan } from 'services/generatedClientFromSwagger';
import { useSelector } from 'react-redux';
import { RootState } from 'store/index';

export const useEnableRetryLogic = ({ challenge, plan }: { challenge?: PropChallenge; plan?: PropPlan }) => {
    const takenRetries =
        useSelector((store: RootState) => store.prop_myPlans.takenRetries) || ({} as Record<string, boolean>);

    const { t } = useTranslation('prop');
    const { statusTerm } = usePropStatusHook({ challengeStatus: challenge?.challengeStatus });
    const { retryOptions, planId, finalDepositAmount, endTime } = plan?.pPlan || {};

    const { gapInMilliseconds, isExpirationDateRetryActive } = useRetryPlanDuration({
        endTime: endTime,
        retryExpirationDays: retryOptions?.retryExpirationDays,
    });

    return {
        isRetryActive:
            !takenRetries[planId || ''] &&
            !!challenge?.challengeStatus &&
            statusTerm === t('card.failed') &&
            retryOptions?.isRetryAvailable &&
            (retryOptions?.retryCounter ?? 0) < (retryOptions?.retryLimit ?? 0) &&
            isExpirationDateRetryActive &&
            !retryOptions?.retryNextPlanReference &&
            (finalDepositAmount ?? 0) > 0,
        gapInMilliseconds,
    };
};
