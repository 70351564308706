import { kycSteps as fscaRegulationKycSteps } from './regulation/FscaRegulationKycFlow/kyc.scheme';
import { kycSteps as asRegulationKycSteps } from './regulation/AsRegulationKycFlow/kyc.scheme';
import { kycSteps as byRegulationKycSteps } from './regulation/ByRegulationKycFlow/kyc.scheme';
import { kycSteps as sanusRegulationKycSteps } from './regulation/SanusRegulationKycFlow/kyc.scheme';
import config from 'config/common';
import { KycFragment } from 'services/generatedClientFromSwagger';

export const kycType = config.kycType;
export const kycActive = config.featuresFlags['kyc'];

const getKycStepsLength = () => {
    if (!kycActive) return undefined;
    switch (kycType) {
        case 'fsca-regulation':
            return fscaRegulationKycSteps.length;
        case 'sanus-regulation':
            return sanusRegulationKycSteps.length;
        case 'as-regulation':
            return asRegulationKycSteps.length;
        case 'by-regulation':
            return byRegulationKycSteps.length;
        default:
            return undefined;
    }
};

const getFailedTerminationActive = () => {
    if (!kycActive) return false;
    switch (kycType) {
        case 'lfs':
            return true;
        case 'wgm':
            return true;
        case 'fsca': // comes from .env ask amit
            return true;
        case 'fsca-regulation':
            return true;
        case 'as-regulation':
            return true;
        case 'by-regulation':
            return true;
        case 'sanus-regulation':
            return true;
        case 'aml':
            return false;
        case 'fsca-wgm':
            return true;
        default:
            return true;
    }
};

export const isBetweenWarningScoreRange = (kycScore: number) => {
    if (!kycActive) return false;
    switch (kycType) {
        case 'lfs':
            return kycScore >= 1 && kycScore < 75;
        case 'wgm':
            return kycScore >= 1 && kycScore < 99;
        case 'fsca':
            return kycScore < 1;
        case 'fsca-regulation':
            return kycScore >= 1 && kycScore < 30;
        case 'as-regulation':
            return kycScore >= 1 && kycScore < 30; // TODO: check kyc warning range score (in crm)
        case 'by-regulation':
            return kycScore >= 1 && kycScore < 6; // TODO: check kyc warning range score (in crm)
        case 'sanus-regulation':
            return kycScore >= 0 && kycScore < -900;
        case 'aml':
            return kycScore >= 1 && kycScore < 25;
        case 'fsca-wgm':
            return kycScore < 1;
        default:
            return false;
    }
};

export const parseKycFragments = (values: any): KycFragment[] => {
    const valuesClean = JSON.parse(JSON.stringify(values));
    const questionsMap = Object.entries(valuesClean).filter(([key, value]) => key.startsWith('question_'));
    let fragments: KycFragment[] = [];
    questionsMap.forEach((question: [string, any], index) => {
        let fieldId = question[0]; // `question_${number}` | `question_${number}_answer_${number}`
        let fieldValue = question[1];
        let questionAnswerIds = fieldId.replace('question_', '').replace('answer_', '').split('_'); // [questionId, answerId(?), answerNum(?)]
        let questionId = Number(questionAnswerIds[0]);
        let answerId = questionAnswerIds[1];
        let answers = Array.isArray(fieldValue) ? fieldValue : [fieldValue];
        answers = answers
            ?.map((answer) => ({
                answerId: answerId !== undefined ? Number(answerId) : Number(answer),
                answerText: answerId !== undefined ? answer : null,
            }))
            .filter((q) => q.answerId !== null);
        let existsQuestionIndex = fragments.findIndex((question) => Number(question?.questionId) === questionId);
        let questionIndex = existsQuestionIndex > -1 ? existsQuestionIndex : index;
        if (existsQuestionIndex > -1) {
            let existsAnswers = fragments[questionIndex]?.answers;
            if (existsAnswers) {
                existsAnswers = existsAnswers.filter((existsAnswer) => {
                    let duplicatedAnswer = answers.some(
                        (a) => Number(a.answerId) === Number(existsAnswer.answerId) && existsAnswer.answerText === null
                    );
                    return !duplicatedAnswer;
                });
                answers = [...existsAnswers, ...answers];
                answers = answers.filter((a) => a.answerText !== undefined);
            }
        }
        fragments[questionIndex] = { questionId: questionId, answers: answers };
    });

    // remove empty indexes...
    fragments = fragments.filter(function (el) {
        return el != null;
    });

    return fragments;
};

export const kycFlowLength = getKycStepsLength();
export const failedTerminationActive = getFailedTerminationActive();
