import i18next, { InitOptions } from 'i18next';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { defaultI18Lng, locales } from './config/i18n';
import config from 'config/common';
import { getSearchParamFromUrl } from 'helpers/urlParamaters';
import { merge } from 'lodash';

const overrides = {};

enum DtOptionsEnum {
    KEY = 'KEY',
    VALUE = 'VALUE',
    KEY_VALUE = 'KEY_VALUE',
}
const postProcessLog = {
    name: 'nameOfProcessor',
    process: function (value, key, options, translator) {
        const dtOptions = localStorage.getItem('dtOptions');
        if (dtOptions) {
            switch (dtOptions) {
                case DtOptionsEnum.KEY:
                    return key;
                case DtOptionsEnum.VALUE:
                    return value;
                case DtOptionsEnum.KEY_VALUE:
                    return `${value} (${key})`;
            }
        }
        return value;
    },
};

export const i18nOptions: InitOptions = {
    postProcess: [postProcessLog.name],
    debug: false,
    fallbackLng: defaultI18Lng,
    preload: [defaultI18Lng],
    lng: getSearchParamFromUrl('lang') || localStorage.getItem('i18nextLng') || defaultI18Lng,
    supportedLngs: config.supportedLanguages,
    defaultNS: 'common',
    ns: [
        'overrides',
        'account-settings',
        'kycBroker9',
        'kycBroker21',
        'kycBroker11',
        'client-support',
        'common',
        'components',
        'dashboard',
        'email-confirmation',
        'email-verification',
        'forgot-password',
        'kycBroker14',
        'kycBroker15',
        'graphIndicators',
        'kycBroker16',
        'kyc',
        'kycBroker3',
        'kycBroker6',
        'kycBroker2',
        'login',
        'msgPopup',
        'myWallet',
        'new-password',
        'notifications',
        'phone-verification',
        'register',
        'kycbroker17',
        'social',
        'kycBroker7',
        'trading-accounts',
        'trading-analysis',
        'upload-documents',
        'kycBroker19',
        'notification',
        'refer-friend',
        'prop',
    ],
    load: 'all',
    interpolation: { escapeValue: false },
    lowerCaseLng: true,
    react: { useSuspense: true },
    backend: {
        parse: (data, language: string, namespace: string) => {
            const dataObj = JSON.parse(data);
            if (namespace === 'overrides') {
                overrides[language] = dataObj;
                for (const overrideNamespace in overrides[language]) {
                    i18next.addResourceBundle(
                        language,
                        overrideNamespace,
                        overrides[language][overrideNamespace],
                        true,
                        true
                    );
                }
                return {};
            }

            if (overrides?.[language]?.[namespace]) {
                const mergedData = merge(dataObj, overrides[language][namespace]);
                return mergedData;
            }
            return dataObj;
        },
    } as HttpBackendOptions,
};
i18next
    .use(Backend)
    .use({ type: 'postProcessor', name: postProcessLog.name, process: postProcessLog.process })
    .use(new LanguageDetector(null, { lookupQuerystring: 'lang' }))
    .use(initReactI18next)
    .init(i18nOptions);

export const onChangeLng = (lng: string) => {
    if (!Object.keys(locales).some((language) => lng === language)) {
        const cleanedLanguage = lng;
        const cleanedLanguageIsFound = Object.keys(locales).some((language) => cleanedLanguage === language);
        const newLanguage = cleanedLanguageIsFound ? cleanedLanguage : defaultI18Lng;
        return i18next.changeLanguage(newLanguage);
    }
    // changePlatformSrc(undefined, lng, undefined);
    const html = document.getElementsByTagName('html')[0];

    html.setAttribute('lang', lng);
    let dir = 'ltr';
    try {
        dir = locales[lng].dir;
    } catch {}
    try {
        document.body.dir = dir;
        return { direction: dir };
    } catch {}
};

window['i18ntest'] = i18next;

export default i18next;
