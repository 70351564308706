import React from 'react';
import TradingSuggestions from 'components/TradingSuggestions';
import andoridSrc from 'assets/images/android.png';
import appleSrc from 'assets/images/apple.png';
import mt4Src from 'assets/images/mt4.png';
import { useTranslation } from 'react-i18next';
import useStyles from './panel-info.style';
import FloatingPaper from 'components/FloatingPaper/floating-paper.render';
import kyc_image from 'assets/images/kyc_banner.png';
import upload_image from 'assets/images/upload_banner.png';
import { Pages } from 'interfaces/main';
import { kyc_status } from 'features/kyc/store/selector';
import { KycButtonProps } from 'features/kyc/store/interface';
import { sumsub_status } from 'features/sumsub/store/selector';
import { SumsubButtonProps } from 'features/sumsub/store/interface';
import { connect } from 'react-redux';
import config from 'config/common';
import { useLocation } from 'react-router-dom';
import station_light from 'assets/images/station_light.png';
import station_dark from 'assets/images/station_dark.png';
import { useTheme } from '@material-ui/core';
import { sideImage } from 'config/dynamicAssets';

export interface PanelInfoProps {
    dashboardView?: boolean;
    kyc_menuBannerProps?: KycButtonProps;
    sumsub_menuBannerProps?: SumsubButtonProps;
}

export const mapStateToProps = (state) => ({
    kyc_menuBannerProps: kyc_status(state),
    sumsub_menuBannerProps: sumsub_status(state),
});

export const mapDispatchToProps = {};
const downloadLinks = (theme) => [
    {
        href: config.downloadLinks.mt4,
        icon: mt4Src,
        textT: 'links.platform.mt4',
        outsideLink: true,
        newTab: true,
    },
    {
        href: config.downloadLinks.mt5,
        icon: mt4Src,
        textT: 'links.platform.mt5',
        outsideLink: true,
        newTab: true,
    },
    {
        href: config.downloadLinks.ios,
        appCode: config.downloadLinks.iosAppCode,
        icon: appleSrc,
        textT: 'links.platform.apple',
        appCodeText: 'links.platform.subApple',
        outsideLink: true,
        newTab: true,
    },
    {
        href: config.downloadLinks.tradingPlatform,
        icon: theme.palette.type === 'light' ? station_light : station_dark,
        textT: 'links.platform.tradingPlatformStation',
        outsideLink: true,
        newTab: true,
    },
    {
        href: config.downloadLinks.android,
        appCode: config.downloadLinks.androidAppCode,
        icon: andoridSrc,
        textT: 'links.platform.android',
        appCodeText: 'links.platform.subAndroid',
        outsideLink: true,
        newTab: true,
    },
];
const PanelInfo: React.FunctionComponent<PanelInfoProps> = (props) => {
    const { kyc_menuBannerProps, sumsub_menuBannerProps, dashboardView } = props;
    const classes = useStyles({ dashboardView });
    const theme = useTheme();
    const { t } = useTranslation('common');
    const location = useLocation();

    const PaperComponent = () => {
        const hiddenPages = [Pages.DASHBOARD, Pages.KYC, Pages.SUMSUB_UPLOAD, Pages.BE_PRO];

        if (hiddenPages.some((page) => location.pathname.indexOf(page) > -1)) {
            return null;
        }

        if (kyc_menuBannerProps) {
            return (
                <div>
                    <FloatingPaper
                        description={t('panel_info.kyc.description')}
                        title={t('panel_info.kyc.title')}
                        imgSrc={kyc_image}
                        btnText={t('panel_info.kyc.btnText')}
                        link={kyc_menuBannerProps.link}
                        btnColor="secondary"
                        align="start"
                    />
                </div>
            );
        } else if (sumsub_menuBannerProps) {
            return (
                <div>
                    <FloatingPaper
                        description={t('panel_info.kyc_upload_documents.description')}
                        title={t('panel_info.kyc_upload_documents.title')}
                        imgSrc={upload_image}
                        btnText={t('panel_info.kyc_upload_documents.btnText')}
                        link={sumsub_menuBannerProps.link}
                        btnColor="secondary"
                        align="start"
                    />
                </div>
            );
        }
        return null;
    };

    const shouldRenderSideImage = !kyc_menuBannerProps && !sumsub_menuBannerProps;

    return (
        <div className={classes.wrapper}>
            <PaperComponent />
            {config.enableSignals && <TradingSuggestions />}
            {shouldRenderSideImage && !config.enableSignals && (
                <div className={classes.sideImageWrapper}>
                    <img src={sideImage} alt="backgroundimage" className={classes.sideImage} />
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PanelInfo));
//
