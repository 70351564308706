import { createSelector } from 'reselect';
import { RootState } from 'store';
import { AccountInfoParsedIsUsResidentEnum } from 'services/generatedClientFromSwagger/models';
import {
    kycActive,
    kycType,
    kycFlowLength,
    failedTerminationActive,
    isBetweenWarningScoreRange,
} from '../kyc-functions';
import { Pages } from 'interfaces/main';
import { IconsType } from 'components/Icon';
import { KycButtonProps } from './interface';
import { kyc_fsca_regulation_currentStep } from '../regulation/FscaRegulationKycFlow/selectorFunctions';
import config from '../../../config/common';
import { kyc_as_regulation_currentStep } from '../regulation/AsRegulationKycFlow/selectorFunctions';
import { kyc_by_regulation_currentStep } from '../regulation/ByRegulationKycFlow/selectorFunctions';
import { kyc_sanus_regulation_currentStep } from '../regulation/SanusRegulationKycFlow/selectorFunctions';

const rootReducerSelector = (state: RootState) => state;
const userReducerSelector = (state: RootState) => state.user;
const kycReducerSelector = (state: RootState) => state.kyc;

export const kyc_updateLoading = createSelector(kycReducerSelector, (kyc) => kyc.updateLoading);

export const kyc_completed = createSelector(userReducerSelector, (user) => {
    if (!kycActive) return true;

    if (config.featuresFlags['czRegulation']) {
        const kyc = user.userInfo.regulationKyc?.kycs?.[config.kycType || ''];
        return kyc?.completed && kyc?.passed;
    }

    switch (kycType) {
        case 'kimbo': //todo
        case 'aml':
            return Boolean(user.userInfo.custom_field_passedappropriatenesstest);
        default:
            return (
                Boolean(user.userInfo.custom_field_passedappropriatenesstest) &&
                (Boolean(user.userInfo.custom_field_passedsuitabilitytest)
                    ? true
                    : Boolean(user.userInfo.custom_field_acceptwarningandproceedtrading) ||
                      Boolean(user.userInfo.custom_field_acceptwarningandproceedtrading))
            );
    }
});

export const kyc_failed_demo = createSelector(rootReducerSelector, (root) => {
    if (!kycActive) {
        return false;
    }

    switch (kycType) {
        case 'lfs': {
            // todo
            return false;
        }
        case 'wgm': {
            const kycScore = Number(root.user.userInfo.custom_field_registrationnumber);
            return !kyc_completed(root) && kycScore < 1;
        }
        default: {
            return false;
        }
    }
});

export const kyc_currentStep = createSelector(rootReducerSelector, (root) => {
    if (!kycActive) return 12;

    switch (kycType) {
        case 'fsca-regulation': {
            return kyc_fsca_regulation_currentStep(root);
        }
        case 'sanus-regulation': {
            return kyc_sanus_regulation_currentStep(root);
        }
        case 'as-regulation': {
            return kyc_as_regulation_currentStep(root);
        }
        case 'by-regulation': {
            return kyc_by_regulation_currentStep(root);
        }
        default:
            return 11;
    }
});

export const kyc_maxStep = () => {
    let maxStep = 0;
    switch (kycType) {
        case 'lfs': {
            maxStep = 12;
            break;
        }

        case 'wgm': {
            maxStep = 11;
            break;
        }

        case 'fsca': {
            maxStep = 5;
            break;
        }

        case 'fsca-regulation': {
            maxStep = 5;
            break;
        }

        case 'sanus-regulation': {
            maxStep = 4;
            break;
        }

        case 'as-regulation': {
            maxStep = 10;
            break;
        }

        case 'by-regulation': {
            maxStep = 2;
            break;
        }

        case 'kimbo': {
            maxStep = 1;
            break;
        }

        case 'aml': {
            maxStep = 13;
            break;
        }

        case 'fsca-wgm': {
            maxStep = 5;
            break;
        }
    }

    return maxStep;
};

export const kyc_kycButtonType = createSelector(userReducerSelector, (user) => {
    if (!kycActive) return undefined;
    const isSpain = (user.userInfo.country || '').toLowerCase() === 'spain';
    let kycScore = Number(user.userInfo.custom_field_registrationnumber);

    if (config.featuresFlags['czRegulation'] && config.kycType) {
        const kyc = user.userInfo.regulationKyc?.kycs?.[config.kycType];
        kycScore = Number(kyc?.score) || 0;
    }

    if (
        isSpain &&
        !user.userInfo.custom_field_assignforspanishdeclaration &&
        user.userInfo.custom_field_whatlevelofriskcanyoutolerate
    )
        return 'kyc_spain';
    if (
        failedTerminationActive &&
        user.userInfo.custom_field_passedappropriatenesstest &&
        !user.userInfo.custom_field_passedsuitabilitytest &&
        !user.userInfo.custom_field_acceptwarningandproceedtrading &&
        !user.userInfo.custom_field_clickedoncancelterminationrequest &&
        isBetweenWarningScoreRange(kycScore)
    )
        return 'kyc_cancel_termination';
    if (
        user.userInfo.custom_field_passedappropriatenesstest &&
        !user.userInfo.custom_field_acceptwarningandproceedtrading &&
        !isBetweenWarningScoreRange(kycScore)
    )
        return undefined;
    return 'kyc';
});

export const kyc_donePreCondition = createSelector(rootReducerSelector, (root) => {
    if (!kycActive) return true;
    const currentStep = kyc_currentStep(root);
    return currentStep > -1;
});

export const kyc_isFailed = createSelector(rootReducerSelector, (root) => {
    if (!kycActive) return false;
    return Boolean(
        root.user.userInfo.isUsResident === AccountInfoParsedIsUsResidentEnum.Yes ||
            (root.user.userInfo.custom_field_passedappropriatenesstest &&
                (root.user.userInfo.custom_field_acceptwarningandproceedtrading ||
                root.user.userInfo.custom_field_clickedoncancelterminationrequest
                    ? false
                    : root.user.userInfo.custom_field_registrationnumber &&
                      Number(root.user.userInfo.custom_field_registrationnumber) < 1))
    );
});

export const kyc_status = createSelector(rootReducerSelector, (root): KycButtonProps | undefined => {
    const kycIsCompleted = kyc_completed(root);
    const buttonType = kyc_kycButtonType(root);

    if (!kycActive || !buttonType || kycIsCompleted) return undefined;
    const currentStep = kyc_currentStep(root);
    let stepsPercent = kycFlowLength && Math.round(((currentStep + 1) / kycFlowLength) * 100);
    if (stepsPercent && stepsPercent >= 100) stepsPercent = undefined;

    const maxStep = kyc_maxStep();

    return {
        possibleLinks: [`/${Pages.KYC}`],
        link: `/${Pages.KYC}/${Pages[`KYC__${currentStep === maxStep ? currentStep : currentStep + 1}`]}`,
        buttonType,
        icon: IconsType.my_profile,
        stepsPercent: stepsPercent?.toString(),
    };
});

export const kyc_amlKycFailed = createSelector(rootReducerSelector, (root) => {
    return kycType === 'aml' && Number(root.user.userInfo.custom_field_registrationnumber) < 25;
});
