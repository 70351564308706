import config, { DepositCrypto, DepositLinks } from 'config/common';
import { locales } from 'config/i18n';
import axios from 'services/axios';
import { EConfigTypes } from './actionsTypes';
import { WithdrawalTypes } from '../../interfaces/main';

export const config_getEnv = () => {
    return async (dispatch) => {
        if (!Boolean(config.featuresFlags['onlineConfig'])) {
            return;
        }
        try {
            dispatch({ type: EConfigTypes.GET_ENV_START });
            const res = await axios.ConfigApi.configApiControllerGetClientConfig();
            const data = res?.data;
            if (data) {
                config.featuresFlags['notificationSettings'] =
                    data.REACT_APP_FEATURES_FLAGS.notificationSettings || false;
                config.featuresFlags['notifications'] = data.REACT_APP_FEATURES_FLAGS.notifications || false;
                config.wtFeaturesFlags['tourGuide'] = data.REACT_APP_WT_FEATURES_FLAGS.tourGuide || false;
                Object.values(data.REACT_APP_I18N_LNG_LIST).forEach((lang) => {
                    locales[lang.code] = lang;
                });
                Object.keys(locales).forEach((code) => {
                    if (!data.REACT_APP_I18N_LNG_LIST[code]) {
                        delete locales[code];
                    }
                });
                config.downloadLinks.mt4 = data.REACT_APP_DOWNLOAD_MT4 ? data.REACT_APP_DOWNLOAD_MT4.toString() : '';
                config.downloadLinks.mt5 = data.REACT_APP_DOWNLOAD_MT5 ? data.REACT_APP_DOWNLOAD_MT5.toString() : '';
                config.downloadLinks.tradingPlatform = data.REACT_APP_DOWNLOAD_SIRIX
                    ? data.REACT_APP_DOWNLOAD_SIRIX.toString()
                    : '';
                config.downloadLinks.android = data.REACT_APP_DOWNLOAD_ANDROID
                    ? data.REACT_APP_DOWNLOAD_ANDROID.toString()
                    : '';
                config.preDepositFlags = (data.REACT_APP_PRE_DEPOSIT_FLAGS as any) || {};
                config.downloadLinks.ios = data.REACT_APP_DOWNLOAD_IOS ? data.REACT_APP_DOWNLOAD_IOS.toString() : '';
                config.withdrawalTypes = (data.REACT_APP_WITHDRAWAL_TYPES as WithdrawalTypes[]) || [];
                config.depositCrypto = (data.REACT_APP_DEPOSIT_CRYPTO as DepositCrypto[]) || [];
                config.depositLinks = (data.REACT_APP_DEPOSIT_LINKS as DepositLinks[]) || [];
                config.cryptoCurrencies = (data.REACT_APP_WITHDRAWAL_CRYPTO_CURRENCIES as string[]) || [];
                config.depositOutsource = data?.REACT_APP_DEPOSIT_OUTSOURCE || {};
                config.tradersEducation = data?.REACT_APP_TRADERS_EDUCATION || {};
                config.enableSignals = data?.REACT_APP_SIGNALS || false;
                config.redirectToExternalEndpoint = data?.REACT_APP_ENABLE_REDIRECT_TO_EXTERNAL_URL ?? {};
                config.outsourceWithdrawal = data.REACT_APP_OUTSOURCE_WITHDRAWAL
                    ? data.REACT_APP_OUTSOURCE_WITHDRAWAL.toString()
                    : '';
                config.cryptoSubtitle = data.REACT_APP_CRYPTO_SUBTITLE ? data.REACT_APP_CRYPTO_SUBTITLE.toString() : '';
                config.featuresFlags['passwordWithSpecialCharacters'] =
                    data.REACT_APP_FEATURES_FLAGS.passwordWithSpecialCharacters || false;
                config.clientSupportSubjectSelectOptions = data.REACT_APP_CLIENT_SUPPORT_SUBJECTS || [];
                config.dynamicFileTypes = data.REACT_APP_UPLOAD_FILES_TYPES || [];

                config.refund_cancellation_policy_modal = data.REACT_APP_REFUND_POLICY_FUNDED_MODAL || '';
                config.intercomAppId = data.REACT_APP_INTERCOM_APP_ID || config.intercomAppId;
                config.featuresFlags['intercom'] = data.REACT_APP_FEATURES_FLAGS.intercom;
                config.logoRedirectUrl = data.REACT_APP_ENABLE_REDIRECT_TO_EXTERNAL_URL_LOGO;

                config.logoSettings = data?.REACT_APP_LOGO_SETTINGS;
                dispatch({ type: EConfigTypes.GET_ENV_SUCCESS, payload: data, locales });
                localStorage.setItem('locales', JSON.stringify(locales));
            } else {
                dispatch({ type: EConfigTypes.GET_ENV_FAIL });
            }
        } catch (error) {
            dispatch({ type: EConfigTypes.GET_ENV_FAIL });
        }
    };
};
