import { createSelector } from 'reselect';
import { RootState } from 'store';

import { AccountInfoParsedCustomFieldSolicitationStatusEnum } from 'services/generatedClientFromSwagger';
import config from 'config/common';

export const reverseSolicitationActive = config.featuresFlags['kycBroker17'];

const userReducerSelector = (state: RootState) => state.user;
const reverseSolicitationSelector = (state: RootState) => state.reverseSolicitation;

export const reverseSol_updateLoading = createSelector(
    reverseSolicitationSelector,
    (reverseSolicitation) => reverseSolicitation.updateLoading
);

export const reverseSol_completed = createSelector(userReducerSelector, (user) => {
    const { custom_field_solicitation_required, custom_field_solicitation_status } = user.userInfo;
    if (!reverseSolicitationActive || !custom_field_solicitation_required) return true;
    return custom_field_solicitation_status === AccountInfoParsedCustomFieldSolicitationStatusEnum.Confirmed;
});

export const reverseSol_rejected = createSelector(userReducerSelector, (user) => {
    const { custom_field_solicitation_required, custom_field_solicitation_status } = user.userInfo;
    if (!reverseSolicitationActive || !custom_field_solicitation_required) return false;
    return custom_field_solicitation_status === AccountInfoParsedCustomFieldSolicitationStatusEnum.Declined;
});
