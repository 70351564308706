import { makeStyles } from '@material-ui/core';
import { cssEnd, customGap, isDesktop, isRtl, isTablet, oppositeCss, isMobile } from 'helpers/device_helper';

interface StylesProps {
    sideMenuState: boolean;
}

export default makeStyles((theme) => ({
    kycBadge: {
        display: 'flex',
        padding: '2px  10px 2px  8px',
        alignItems: 'center',
        gap: 4,
        height: 24,
        width: 86,
        borderRadius: 9999,
        border: `1px solid  ${theme.palette.successVariants[200]}`,
        background: `${theme.palette.successVariants[500]}`,
        color: theme.palette.successVariants[700],
        textAlign: 'center',
    },
    overlay: (props: StylesProps) => ({
        height: '100%',
        width: 'auto',
        zIndex: 'initial',
        overflow: 'hidden',
        background: 'unset',
        ...isTablet({
            position: 'initial',
        }),

        ...isDesktop({
            height: '100%',
            width: 'auto',
            zIndex: 'initial',
            overflow: 'auto',
            background: 'unset',
            position: 'initial',
        }),
    }),
    overlay__open: (props: StylesProps) => ({
        width: '100vw',
        zIndex: theme.zIndex.drawer,
        overflow: 'hidden',
        background: 'rgba(52, 64, 84, 0.60)',
        position: 'fixed',
        ...isTablet({
            position: 'fixed',
        }),
        ...isDesktop({
            height: '100%',
            width: 'auto',
            zIndex: 'initial',
            overflow: 'auto',
            background: 'unset',
            position: 'initial',
        }),
    }),
    menu_container: (props: StylesProps) => ({
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.background.default,
        width: 'unset',
        overflowX: 'hidden',
        overflowY: 'overlay',
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        gridTemplateRows: 'repeat(3, auto) 1fr',
        gap: `${theme.spacing(5)}rem`,
        ...oppositeCss(
            theme.direction,
            'padding',
            `${theme.spacing(8)}rem 0 ${theme.spacing(2)}rem ${theme.spacing(isDesktop() ? 2 : 1)}rem`
        ),
        ...oppositeCss(theme.direction, 'margin', '0 0 0 -27.5rem'),
        ...cssEnd(theme.direction, 'border', `0.1rem solid ${theme.palette.action.disabledBackground}`),
        ...isTablet({
            width: '6.4rem',
            margin: 'unset',
        }),
    }),
    menu_container__open: () => ({
        display: 'flex',
        flexFlow: 'column',
        margin: 'unset',
        width: '311px',
        ...isTablet({
            width: '28rem',
            margin: 'unset',
        }),
        position: 'relative',
    }),
    hamburger: () => ({
        display: 'flex !important',
        flexDirection: 'column',
        ...isTablet({
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
        }),
    }),
    menu_list_wrapper: (props: StylesProps) => ({
        paddingInlineEnd: `${theme.spacing(2)}rem`,
        position: 'relative',
        display: 'flex',
        alignItems: 'start',
        flexFlow: 'column',
        width: '100%',
        gap: `${theme.spacing(1)}rem`,
        '&:first-child:after': {
            display: props.sideMenuState ? 'block' : 'none',
        },
        '&:last-child': {
            gap: 'unset',
            ...customGap(theme.spacing(1), 'vertical'),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    }),
    menu_list_wrapper__open: { alignItems: 'stretch !important' },
    menu_list_grow_stack: {
        display: 'flex',
        flexFlow: 'column',
        flexGrow: 1,
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    menu_list_divider: {
        height: '0.1rem',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[300] : theme.palette.action.disabledBackground,
    },
    seperator: {
        width: '100%',
        height: '0.1rem',
        backgroundColor: 'grey',
    },
    shareIconsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        ...customGap(1.6, 'vertical'),
    },
    shareIconsContainer__openMenu: () => ({
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.background.default,
        width: '310px',
        ...isRtl({
            left: 'unset',
            right: '0',
        }),
        ...isTablet({
            width: '274px',
        }),
    }),
    shareIconsContainer__closedMenu: { '& :not(img),:not(:first-child)': { display: 'none' } }, // ideally move to js
    closeButton: () => ({
        position: 'fixed',
        right: 0,
        top: 0,
        width: '40px',
        height: '40px',
        margin: '12px 8px',
        color: theme.palette.common.white,
        ...isRtl({ right: 'unset', left: 0 }),
        ...isTablet({
            display: 'none',
        }),
    }),
    collapseWrapper: {},
    collapsedItemMain: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
        color: theme.palette.text.primary,
    },
    collapsedItem: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        padding: '8px 8px 8px 35px',
        color: theme.palette.text.primary,
    },
    collapsedIconMain: { margin: '0 15px 0 15px' },
    collapsedIcon: { margin: '0 15px' },
    root: { padding: '0' },
    emptySpace: { height: '106px' },
    shareButtonStyle: {
        padding: '5px',
        '&:hover': { backgroundColor: theme.palette.disabled.background, borderRadius: '8px' },

        collapsedItem__active: {
            backgroundColor: theme.palette.grayVariants[100],
            borderRadius: '0.8rem',
            color: theme.palette.grayVariants[900],
            fontWeight: 600,
        },
        collapseButton: () => ({
            '&:hover,&:focus,&:active': { backgroundColor: 'transparent' },
            marginRight: '12px',
            ...isRtl({ marginLeft: '12px' }),
        }),
        flagsAndText: {
            display: 'flex',
            alignItems: 'center',
        },
    },
    menuItemWrapper: {
        display: 'inline-block',
        width: '100%',
    },
    tradingPlatformLogo: { maxHeight: 12, userSelect: 'none' },
}));
